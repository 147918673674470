<template>
  <div class="notfound-page">
    <div class="content">
      <img src="//download-cos.yofish.com/ad-gongjushiyebu/20220608151910980-404.png" alt="">
      <p>暂无内容哦~</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  mounted() {
    document.title = '404';
  },
};
</script>
<style lang="scss" scoped>
.notfound-page {
  width: 100%;
  min-height: 100%;
  background: linear-gradient(180deg, #F6F6F6 0%, #F6F6F6 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 300px;
    text-align: center;
    img {
      width: 100%;
    }
    p {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 40px;
      margin-top: 7px;
    }
  }
}
</style>
